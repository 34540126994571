import React, { Component } from 'react';
import '../App.css';
import './Footer.css';
import Nav from '../components/Nav';
import { SocialIcon } from 'react-social-icons';
import ecopolluters from '../images/ecopolluters.svg';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';

class Footer extends Component {

  state = {
    open: false,
  };
 
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {

    const { open } = this.state;

    let navAttribute = this.props.page;
    if (navAttribute !== "ProtectLand") {
      navAttribute = "Footer";
    }
    console.log(navAttribute);

    return (        

        <div className="footer">
          <div className="findUs">Find us on</div>
          <div className="social-section">
            <div className="social fac">
              <SocialIcon className="icon" url="https://www.facebook.com/Ecopolluters-112149543528301/?eid=ARBpbIuZygo64Yd0W9hgh_6osBi68vc9NuK8dUm3DgKvpJfs4YslZcF-gwN_hRdpFJtxOLnTg5AcGCNQ" style={{ height: 50, width: 50 }} bgColor="#98A1AB"/>
            </div>  
            <div className="social">
              <SocialIcon className="icon" url="https://twitter.com/ecopolluters" style={{ height: 50, width: 50 }} bgColor="#98A1AB"/>
            </div>  
            <div className="social">
              <SocialIcon className="icon" url="https://www.linkedin.com/company/28890458" style={{ height: 50, width: 50 }} bgColor="#98A1AB"/>
            </div>
            <div className="social insta">
              <SocialIcon className="icon" url="https://www.instagram.com/eco.polluters/" style={{ height: 50, width: 50 }} bgColor="#98A1AB"/>
            </div>
          </div>
          <div>
            <Nav page={navAttribute}/>
          </div>
          <div>
            <div className="rights">All Rights Reserved • <Link onClick={this.onOpenModal}>Privacy Policy</Link> </div>
          </div>
          <Link to="/">
                <img src={ecopolluters} alt="ecopolluters"/>
          </Link>
          
          
          <Modal open={open} onClose={this.onCloseModal} center>
            <h2>Privacy Policy</h2>
            <h3>1. Google Analytics</h3>
            <div>
              This website uses Google Analytics, a web analysis service of Google Inc. (“Google”). Google Analytics uses “cookies”, text files, which are stored on your computer and enable analysis of the use you make of the website. In addition, this website uses the Google AMP Client ID API to link user activities to AMP pages with those on non-AMP pages via Google Analytics. The Google tracking codes of this website use the “_anonymizeIp()” function. As a result, the IP address within Member States of the European Union or in other States of the Agreement on the European Economic Area is only further processed in abbreviated form to exclude a direct personal reference. The complete IP address is only transmitted to a server from Google in the USA in exceptional cases and abbreviated there. On behalf of the operator of this website, Google uses this information to evaluate your use of this website, create reports about the website activities and provide other services connected with the use of the website and Internet for the website operator. The IP address transmitted from your browser within the context of Google Analytics will not be associated with other data of Google. You can prevent storing of cookies on your computer via a corresponding setting of your browser software. You can also prevent the recording of data generated by a cookie with respect to your use of the website (incl. IP address) by Google as well as the processing of such data by Google by downloading and installing the browser plugin available via the following link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=en.</a>
            </div>
            <h3>2. Hotjar</h3>
            <div>
              To improve your user experience on our internet pages we use software from Hotjar (<a href="http://www.hotjar.com" target="_blank">http://www.hotjar.com</a>, 3 Lyons Range, 20 Bisazza Street, Sliema SLM 1640, Malta, Europe). Using the software, we can measure and evaluate user behavior (mouse movements, clicks, keystrokes, scroll height, etc.) on our websites. For this purpose, cookies are placed on users’ terminals and data from users such as browser information, operating system, time spent on the page, etc. can be stored. You can find more information about the processing of data by Hotjar at <a href="http://www.hotjar.com/privacy" target="_blank">http://www.hotjar.com/privacy.</a> You can object to the use of Hotjar’s services at <a href="https://www.hotjar.com/opt-out" target="_blank">https://www.hotjar.com/opt-out.</a>
            </div>
            <button className="impact-button modal-button" onClick={this.onCloseModal}>CLOSE</button>
          </Modal>

        </div>

    );
    
  }

}

export default Footer;